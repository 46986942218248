import type { ReadonlyJSONValue } from "replicache"

import type { ProjectCreate, ProjectsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function projectCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<ProjectCreate>) {
	const key = `${workspaceId}/projects/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Project already exists")
	}

	const now = new Date()

	const data: ProjectsGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		archived: false,
		color: args.color,
		description: args.description,
		feedbackIds: [],
		icon: args.icon,
		isVisible: null,
		kluGuid: null,
		linearProjectId: "",
		linearTeamIds: [args.linearTeamId],
		linearTeamNames: [],
		name: args.name,
		notes: null,
		progress: null,
		publicDescription: null,
		publicTitle: null,
		roadmaps: [],
		sortOrder: 0,
		state: args.state,
		summary: [],
		summaryUpdatedAtIso: null,
		url: null,
		workspaceId,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
