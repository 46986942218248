import { useCallback, useMemo, useState } from "react"
import * as R from "remeda"

import type {
	CompaniesGet,
	CompanyGet,
	ContactGet,
	ContactsGet,
	IssuesGet,
	IssueUpvotesGet,
	ThreadsGet,
} from "@productlane/api"
import type { PainLevelStatistics } from "@productlane/api/src/browser"
import type { PainLevel } from "@productlane/db"
import { getIssuePainLevel } from "@productlane/api/src/browser"

import { useSortState } from "./global-state"
import { getFilteredThreads } from "./threads"

export type IssueResult = IssuesGet[number] & {
	importance: {
		trend: number
		painLevel: number
		painLevelStatistics: PainLevelStatistics
	}
	segmentIds: string[]
}

export const PainLevelSorting: Record<PainLevel, number> = {
	HIGH: 0,
	MEDIUM: 1,
	LOW: 2,
	UNKNOWN: 3,
} as const

export function getAugmentedIssue({
	companies,
	contacts,
	issue,
	issueUpvotes,
	maxValues,
	threads,
}: {
	companies: CompaniesGet
	contacts: ContactsGet
	issue: IssuesGet[number]
	issueUpvotes: IssueUpvotesGet
	maxValues: Map<string, number>
	threads: ThreadsGet
}) {
	const max = maxValues.get(issue.status)
	if (max === undefined) {
		throw new Error("Max not defined")
	}
	const pain = getIssuePainLevel(issue, threads, issueUpvotes)
	if (pain.painLevel > max) {
		maxValues.set(issue.status, pain.painLevel)
	}
	const issueThreads = threads.filter((i) => issue.feedbackIds.includes(i.id))
	const segmentIds: Array<string> = []
	for (const thread of issueThreads) {
		if (thread.contactId) {
			const contact = contacts.find((c) => c.id === thread.contactId)
			if (contact) {
				segmentIds.push(...contact.segmentIds)
			}
		}
		if (thread.companyId) {
			const company = companies.find((c) => c.id === thread.companyId)
			if (company) {
				segmentIds.push(...company.segmentIds)
			}
		}
	}
	return {
		...issue,
		importance: pain,
		segmentIds,
	}
}

export function useSortedIssues({
	companies,
	contacts,
	issues,
	issueUpvotes,
	segmentIds,
	threads,
}: {
	companies: CompaniesGet
	contacts: ContactsGet
	issues: IssuesGet
	issueUpvotes: IssueUpvotesGet
	segmentIds: string[]
	threads: ThreadsGet
}) {
	const [maxMap, setMaxMap] = useState(new Map<string, number>())
	const { sortByImportance } = useSortState()

	const statusOrder: Record<string, number> = useMemo(
		() => ({
			triage: 1,
			started: 2,
			paused: 3,
			planned: 4,
			unstarted: 5,
			backlog: 6,
		}),
		[],
	)
	const sortIssues = useCallback(
		(issues: Array<IssueResult>) => {
			return R.sortBy(issues, [
				(issue) => statusOrder[issue.status ?? ""] ?? 0,
				"asc",
			])
		},
		[statusOrder],
	)

	const sortedIssues = useMemo(() => {
		const filteredThreads = getFilteredThreads({
			companies,
			contacts,
			segmentIds,
			threads,
		})
		const augmented = []
		const uniqueStates = Array.from(new Set(issues.map((p) => p.status)))
		const maxValues = new Map<string, number>(
			uniqueStates.map((s) => [s, 0] as const),
		)
		for (const issue of issues) {
			const augmentedIssue = getAugmentedIssue({
				companies,
				contacts,
				issue,
				issueUpvotes,
				maxValues,
				threads: filteredThreads,
			})
			augmented.push(augmentedIssue)
		}

		setMaxMap(maxValues)

		const sorted: Array<IssueResult> = sortByImportance
			? R.sortBy(augmented, [(x) => x.importance.painLevel, "desc"])
			: augmented

		if (segmentIds.length > 0) {
			return sortIssues(
				sorted.filter((p) =>
					segmentIds.some((sId) => p.segmentIds.includes(sId)),
				),
			)
		}
		return sortIssues(sorted)
	}, [
		companies,
		contacts,
		issues,
		issueUpvotes,
		segmentIds,
		sortByImportance,
		sortIssues,
		threads,
	])

	return {
		maxMap,
		sortedIssues,
	}
}

export function useCategorizedIssues(
	entity: CompanyGet | ContactGet | undefined,
	issues: IssuesGet,
) {
	return useMemo(() => {
		if (!entity) return {}

		const entityIssues: IssuesGet = []

		for (const fId of entity.feedbackIds) {
			entityIssues.push(...issues.filter((i) => i.feedbackIds.includes(fId)))
		}

		const uniqueEntityIssues = R.uniqBy(entityIssues, (x) => x.id)
		return R.groupBy(
			uniqueEntityIssues,
			(issue) => issue.statusName || "Status",
		)
	}, [entity, issues])
}
