import { create } from "zustand"
import { persist } from "zustand/middleware"

interface SortState {
	sortByImportance: boolean
	setSortByImportance: (value: boolean) => void
	sortByNewest: boolean | null
	setSortByNewest: (value: boolean | null) => void
}

export const useSortState = create<SortState>()(
	persist(
		(set) => ({
			sortByImportance: true,
			setSortByImportance: (value) => {
				set({ sortByImportance: value })
			},
			sortByNewest: null,
			setSortByNewest: (value) => {
				set({ sortByNewest: value })
			},
		}),
		{ name: "sort-state" },
	),
)

export type LastPage = "inbox" | "snoozed" | "processed"

interface InsightState {
	lastPage: LastPage
	setLastPage: (value: LastPage) => void
	offset: number
	setOffset: (value: number) => void
}

export const useInsightState = create<InsightState>()(
	persist(
		(set) => ({
			lastPage: "inbox",
			setLastPage: (value) => {
				set({ lastPage: value })
			},
			offset: 0,
			setOffset: (value) => {
				set({ offset: value })
			},
		}),
		{ name: "insight-state" },
	),
)

type Page = "active" | "closed"
interface RoadmapState {
	lastPage: Page
	setLastPage: (value: Page) => void
}

export const useRoadmapState = create<RoadmapState>()(
	persist(
		(set) => ({
			lastPage: "active",
			setLastPage: (value) => {
				set({ lastPage: value })
			},
		}),
		{ name: "roadmap-state" },
	),
)
