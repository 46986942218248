import React from "react"
import { compareAsc, compareDesc, isAfter, isBefore, parseISO } from "date-fns"
import { filter, flatMap, groupBy, mapValues, pipe, sort, values } from "remeda"

import type { ThreadsGet } from "@productlane/api"
import type { PainLevel } from "@productlane/db"

import { useSortState } from "@/lib/global-state"
import { useThreads } from "@/lib/replicache"

export const useSnoozedThreads = () => {
	const threads = useThreads()

	const { sortByNewest } = useSortState()
	const sortFn = sortByNewest ? compareDesc : compareAsc

	return React.useMemo(
		() =>
			pipe(
				threads,
				filter(({ state }) => state === "SNOOZED"),
				sort((a, b) =>
					sortFn(
						parseISO(a.lastStateChangeAtIso ?? a.createdAtIso),
						parseISO(b.lastStateChangeAtIso ?? b.createdAtIso),
					),
				),
			),
		[threads, sortFn],
	)
}

export const useProcessedThreads = () => {
	const threads = useThreads()

	const { sortByNewest } = useSortState()

	const sortFn = sortByNewest ? compareDesc : compareAsc

	return React.useMemo(
		() =>
			pipe(
				threads,
				filter(({ state }) => state === "PROCESSED"),
				sort((a, b) =>
					sortFn(
						parseISO(a.lastStateChangeAtIso ?? a.createdAtIso),
						parseISO(b.lastStateChangeAtIso ?? b.createdAtIso),
					),
				),
			),
		[threads, sortFn],
	)
}

export const useInboxThreads = (): ThreadsGet => {
	const threads = useThreads()

	const { sortByNewest } = useSortState()

	return React.useMemo(() => {
		const groups = pipe<
			ThreadsGet,
			ThreadsGet,
			Record<Lowercase<PainLevel> | "completed", ThreadsGet>,
			Record<Lowercase<PainLevel> | "completed", ThreadsGet>
		>(
			threads,
			filter((thread) =>
				thread.state === "PROCESSED" || thread.state === "SNOOZED"
					? false
					: true,
			),
			groupBy(({ state, painLevel }) => {
				if (state === "COMPLETED") {
					return "completed"
				}
				return painLevel.toLowerCase()
			}),
			mapValues((group) =>
				pipe(
					group,
					groupBy((thread: ThreadsGet[number]) => {
						if (
							isAfter(
								thread.lastInboundMessageAtIso
									? parseISO(thread.lastInboundMessageAtIso)
									: 0,
								thread.lastOutboundMessageAtIso
									? parseISO(thread.lastOutboundMessageAtIso)
									: 0,
							)
						) {
							return 0 // waiting
						}

						if (
							isAfter(
								thread.lastOutboundMessageAtIso
									? parseISO(thread.lastOutboundMessageAtIso)
									: 0,
								thread.lastInboundMessageAtIso
									? parseISO(thread.lastInboundMessageAtIso)
									: 0,
							)
						) {
							return 1 // replied
						}

						return 2 // no-data
					}),
					mapValues((value, key) =>
						sort(value, (a, b) => {
							if (key === "0") {
								if (
									isBefore(
										a.lastInboundMessageAtIso
											? parseISO(a.lastInboundMessageAtIso)
											: 0,
										b.lastInboundMessageAtIso
											? parseISO(b.lastInboundMessageAtIso)
											: 0,
									)
								) {
									return sortByNewest === true ? 1 : -1
								}
							}

							if (key === "1") {
								if (
									isBefore(
										a.lastOutboundMessageAtIso
											? parseISO(a.lastOutboundMessageAtIso)
											: 0,
										b.lastOutboundMessageAtIso
											? parseISO(b.lastOutboundMessageAtIso)
											: 0,
									)
								) {
									return sortByNewest === true ? 1 : -1
								}
							}

							if (
								isBefore(
									a.createdAtIso ? parseISO(a.createdAtIso) : 0,
									b.createdAtIso ? parseISO(b.createdAtIso) : 0,
								)
							) {
								return sortByNewest === true ? 1 : -1
							}

							return 0
						}),
					),
					values,
					flatMap((group) => group),
				),
			),
		)

		return [
			...(groups.high || []),
			...(groups.medium || []),
			...(groups.low || []),
			...(groups.unknown || []),
			...(groups.completed || []),
		]
	}, [threads, sortByNewest])
}
