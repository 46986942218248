import cheerio from "cheerio"

export const removeStyleAttributes = (html: string) =>
	html.replace(/\sstyle=["'][^"']*["']/g, "")

export const addTargetBlankToLinks = (html: string) => {
	const $ = cheerio.load(html)
	$("a").each((_, link) => {
		$(link).attr("target", "_blank")
		$(link).attr("rel", "noopener noreferrer")
	})
	return $.html()
}
